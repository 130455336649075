<template>
  <div
    component-name="toolbar-filters"
    class="flex items-center gap-lg phablet:gap-2xl bg-transparent py-md"
    :class="{
      'justify-center': useSearch,
      'justify-end': !useSearch,
    }"
  >
    <btn-pill
      icon="filters"
      :class="{
        '!min-w-[45%] phablet:!min-w-[25%] border border-current': useSearch,
      }"
      :title="filtersTitle"
      :filled="useSearch"
      @click.stop.prevent="isOpen = 'filters'"
    />

    <btn-pill
      v-if="useSearch"
      class="!min-w-[45%] phablet:!min-w-[25%] border border-current"
      icon="search"
      :title="$t.labels.search"
      :filled="true"
      @click.stop.prevent="isOpen = 'search'"
    />

    <modal-filters
      :is-open="isOpen"
      :facets="$attrs.facets"
      :total="$attrs.total"
      :use-search="useSearch"
      :use-indoor-outdoor="useIndoorOutdoor"
      @keyword-update="$emit('keyword-update', $event)"
      @close="isOpen = false"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  useSearch: {
    type: Boolean,
    required: false,
    default: true,
  },
  useIndoorOutdoor: {
    type: Boolean,
    required: false,
    default: true,
  },
});
const nuxtApp = useNuxtApp();
const isOpen = ref(false);
const totalFilters = ref(0);
const router = useRouter();
const route = useRoute();

const filtersTitle = computed(() => {
  let l = nuxtApp.$t.labels.filters;

  if (totalFilters.value) {
    l += ` (${totalFilters.value})`;
  }

  return l;
});

watch(
  () => route.query,
  (query) => {
    // for each key in query split the value by comma and count the total
    totalFilters.value = Object.keys(query).reduce((acc, key) => {
      return acc + query[key].split(",").length;
    }, 0);
  },
  {
    immediate: true,
  }
);
</script>
